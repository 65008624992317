body {
    color: rgba(64, 64, 64, 0.65);
    font: 500 1em Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    margin: 0;
    padding: 0;
  }
  
  *:focus {
    outline: none !important;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .Dropzone-img {
    max-width: calc(100vw - 60px);
    max-height: calc(100vw - 60px);
    width: 640px;
    height: 640px;
    border: thin solid rgba(64, 64, 64, 0.15);
    border-radius: 4px;
    object-fit: cover;
  }
  
  .Dropzone {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: thin dashed #c2c2c2;
    background: #fcfcfc;
    max-width: calc(100vw - 40px);
    /* max-height: calc(100vw - 40px); */
    width: 660px;
    height: 660px;
    padding: 10px;
    border-radius: 5px;
    position: relative;
  }
  
  .Dropzone-page {
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .Dropzone-page h3 {
    text-align: center;
    margin: 0px
  }
  
  .prediction {
    max-width: calc(100vw - 40px);
    width: 320;
    height: 320;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
  }
  
  #canvas {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
  