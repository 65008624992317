model-viewer {
    background-color: #414646;
}

.button-ia-style{
    background-color: white;
    border-radius: 4px;
    border: none;
    position: absolute;
    bottom: 16px;
    right: 16px;
    /* z-index: 1000; */
}

#annotation{
    background-color: #888888;
    position: absolute;
    transform: translate(10px, 10px);
    border-radius: 10px;
    padding: 10px;
}

.view-button {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.8);
    display: block;
    font-family: Futura, Helvetica Neue, sans-serif;
    font-size: 10px;
    font-weight: 700;
    max-width: 100px;
    overflow-wrap: break-word;
    padding: 0.5em 1em;
    position: absolute;
    width: max-content;
    height: max-content;
    transform: translate3d(-50%, -50%, 0);
}

.image {
    height: 0px;
    width: 100% ; 
    transition: opacity 0.5s;
    opacity: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    /*transition: '2s'; */
    /* position: 'absolute'; 
    top: 0px; 
    left: 0px */
}

.cont {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* .imageAppearing {
    height: 100% ;
    opacity: 1
} */