@font-face {
  font-family: 'Raleway';
  src: url(./static/Raleway-Regular.ttf);
}

.css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #be2e26;
}

.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  touch-action: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.link-text {
  color: #be2e26;
  text-decoration: underline;
}

.link-text:hover {
  cursor: pointer;
}

.root {
  padding-top: 86px;
  height: 100vh;
}

.menuPautaDetail {
  margin: 0;
  position: absolute;
  top: 50%;
  color: '#ccc';
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiDialogContent-root.dialog-content-ast {
  padding-left: 0 !important;
  padding-right: 0 !important;
  /* margin: 0 !important;
  padding-top: 0 !important; 
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important; */
}

.slider-images {
  /* height: 100vh;
  margin: 0;
  width: 100%; */
}

.slick-slide {
  width: 100%;
  text-align: center;
}

.slick-slide img {
  display: inherit !important;
  height: 100vh;
}

.border-color-primary {
  border-style: solid;
  border-width: 2px;
  border-color: #be2e26;
}

.item-style {
  color: #be2e26;
}

/* Header */

.header-bar {
  /* padding-left: 99px; */
  height: 80px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
  /* z-index: 2; */
}

.nombre {
  font-size: 20px;
  margin: 0px
}

.rol {
  font-size: 18px;
  margin: 0px;
}

.button-font-size {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 'bold'
}

@media screen and (max-width: 660px) {
  /* .header-bar {
    padding-left: 15vw;
  } */
  /* .pageCard {
    margin-left: 15vw
  } */
}

@media screen and (min-width: 660px) {
  /* .pageCard {
    margin-left: 99px
  } */
}

@media screen and (max-width: 560px) {
  .nombre {
    font-size: 3vw;
  }
  .rol {
    font-size: 2.5vw;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pageRoot {
  height: 90%;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 70px;
}

.pageContainer {
  height: 100%;
}

.pageCard {
  /* margin-left: 99px; */
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0px;
  box-shadow: none;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
}

.noNavBarMargin {
  margin-left: 0px;
}

.loginBacgroundImage {
  background: url(./assets/images/login-bg.png) no-repeat 100% 100%;
  background-position: center;
  background-size: cover;
  float: left;
  width: 50%;
  height: 100vh;
  display: block
}

.pageTitle {
  padding: 40px 0px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  /* color: theme.palette.primary.main */
}

.select-elements {
  height: 44px;
  width: 100%;
  border-style: solid;
  border-color: #C4C4C4;
  border-width: 1px;
  border-radius: 10px;
}

.selectionType {
  transition: 500ms;
}

.selectionType:hover {
  cursor: pointer;
  transition: 500ms;
  background-color: #ececec;
}

.list-item {
  position: relative;
  width: 100%;
  padding: 0px 10px;
  height: 6vh;
}

.item-icon-text {
  display: inline-block;
}

.button {
  cursor: pointer;
}

.editarBoton {
  padding: 5px 10px;
  background-color: green;
  color: white;
  border: 1px green solid;
  border-radius: 20px;
}

.borrarBoton {
  padding: 5px 10px;
  background-color: red;
  color: white;
  border: 1px red solid;
  border-radius: 20px;
}

.editarBoton:hover, .borrarBoton[disabled=false]:hover {
  cursor: pointer;
}

.borrarBoton[disabled]{
  border: 1px solid #999999;
  background-color: rgba(255, 0, 0, 0.541);
  color: white;
}


.botonMaquinaContainer {
  width: 100%;
  /* height: 150px; */
  border: transparent;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.botonMaquinaContainer:hover {
  cursor: pointer;
}

.imagenBotonMaquina {
  width: 25%;
  border: #be2e26 1px solid;
  border-radius: 50%;
  position: relative;
  float: left;
  right: -20px;
}

.textoBotonMaquinaContainer {
  width: 70%;
  /* height: 90px; */
  margin: 30px 0px 30px 0px;
  border: #be2e26 1px solid;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 16px;
  text-align: left;
  /* position: absolute; */
  /* float: right; */
  /* top: 50%;
  left: 50%; */
}

.textoBotonMaquinaContainer p {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
}

#imagenContainer:hover {
  cursor: pointer;
}

.dataDialog p {
  margin: 0px 0px 5px 0px;
  font-size: 13px;
}

/* butons */

.iconButton {
  color: #F9F9F9
}

.icon {
  padding: 20px;
  width: 100%;
}

.button {
  min-width: '100%';
}

.buttonSelection {
  background-color: #F9F9F9;
  color: #505050;
  width: 100%;
  height: 100%;
  min-height: 300px;
  border-radius: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: none;
  margin: 10px
}

/* Navbar */

.drawer {
  width: 100px;
  height: 100%;
  white-space: nowrap;
  transition: 1000ms;
  overflow: hidden;
}

.drawerOpen {
  width: 330px;
  height: 100%;
  transition: 1000ms;
  overflow: hidden;
}

.drawerContainer {
  background-color: #282c34;
  width: 100%;
  height: 100vh;
}

.drawerPaper {
  margin-top: 0px;
  background-color: #212121;
  border: none;
  border-end-end-radius: 30px;
  border-top-right-radius: 30px;
  transition: 1000ms;
}

.buttonGeneric:hover {
  cursor: pointer;
}

#container-pdf {
  -webkit-box-shadow: 10px 10px 32px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 32px 2px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 32px 2px rgba(0,0,0,0.75);
}

.files-link {
  margin: 0px;
  margin-bottom: 10px;
  cursor: pointer;
}

.files-link:hover {
  text-decoration: underline;
  font-weight: bold;
}

.item-click {
  width: 100%;
  cursor: pointer;
}

.item-click:hover {
  font-weight: bold;
  text-decoration: underline;
}